import React from 'react';
import Container from '../../../components/common/container';
import HeroSection from '../../../components/common/heroSection';
import styled from 'styled-components';
import VAlign from '../../../components/common/valign';
import H3 from '../../../components/common/headings/h3';
import Small from '../../../components/common/small';
import Button from '../../../components/common/buttons/button';
import Align from '../../../components/common/alignText';
import H1 from '../../../components/common/headings/h1';

const VerticalMiddle = styled(VAlign)`
  padding: 0;
`;

const DemoBox = styled.div`
  min-height: 240px;
  border-radius: 4px;
  width: 100%;
  background: ${({ theme }) => theme.elements.mirasee.backgroundColor};
  color: ${({ theme }) => theme.elements.general.h3.color};
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  & h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.elements.general.h3.color};
  }
  & small {
    margin-top: 20px;
  }
`;

const DemoButton = styled(Button)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0 6px 20px;
`;
const GitHubButton = styled(DemoButton)`
  background: #444;
  color: #fff;
  &:hover {
    background: #000;
  }
`;

const StyledH1 = styled(H1)`
  margin-top: 60px;
  color: ${({ theme }) => theme.elements.home.hero.h1.color};
  margin-bottom: 10px;
`;

const SubHeading = styled(Small)`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 20px;
  & a {
    color: #4154f1;
    text-decoration: none;
  }
`;

const Mark = styled.mark`
  background: #4154f1;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  display: block;
  line-height: 2rem;
  text-align: center;
`;

const Disclaimer = styled.p`
  color: ${({ theme }) => theme.elements.mirasee.color};
`;

const Contributions = () => {
  const paddedContentStyle = { padding: '0 10px 40px' };
  return (
    <HeroSection valign="middle">
      <Container>
        <div className="row">
          <Align direction="center" className="col-lg-12 col-md-12 col-xs-12">
            <StyledH1>{'{%'} Work Samples {'%}'}</StyledH1>
            <SubHeading>
              <H3>@Geek Area. Yes, underneath {`there's`} a link.</H3>
              <a href="https://codeable.io/developers/cristian-simion/" target="_blank" rel="noreferrer">{'<'}View Codeable Profile{'/>'}</a>
            </SubHeading>
          </Align>
          <div className="col-lg-12 col-md-12 col-xs-12" style={paddedContentStyle}>
            <p><Mark>Please also note that these are just samples, not a reflection of the level of expertise that I bring.<br />They have been create to serve as very simple examples.</Mark></p>
            <Disclaimer style={{ fontSize: '12px' }}><strong>Disclaimer: </strong>Since all of these are on public repositories, you are able to see if they are outdated or if {`they've`} been updated with some new practices. I strive to keep this as up to date as possible, but due to large amounts of work needing to be done, I {`can't`} always keep up with having these up-to-date every day. I appreciate your understanding in this matter!</Disclaimer>
          </div>
          <VerticalMiddle className="col-lg-6 col-md-6 col-xs-12">
            <DemoBox>
              <div>
                <H3>NodeJS Sample</H3>
                <div>Creating a <strong>deep clone</strong> functionality and finding partners within radius using latitude and longitude.</div>
                <Small>
                  <GitHubButton href="https://github.com/cristiansimion/purejs-sample" target="_blank">GitHub</GitHubButton>
                </Small>
              </div>
            </DemoBox>
          </VerticalMiddle>
          <VerticalMiddle className="col-lg-6 col-md-6 col-xs-12">
            <DemoBox>
              <div>
                <H3>React Sample</H3>
                <div>A file uploader that sends file to a <strong>NodeJS</strong> endpoint that can be uploaded to a local storage or to <strong>DigitalOcean Object Storage</strong></div>
                <Small>
                  <GitHubButton href="https://github.com/cristiansimion/react-post-to-endpoint">GitHub</GitHubButton>
                </Small>
              </div>
            </DemoBox>
          </VerticalMiddle>
        </div>
        <div className="row">
          <VerticalMiddle className="col-lg-6 col-md-6 col-xs-12">
            <DemoBox>
              <div>
                <H3>REST Api Sample</H3>
                <div>Upload files to <strong>DigitalOcean Object Storage</strong> using this micro-service. <strong>Express</strong> & <strong>NodeJS</strong></div>
                <Small>
                  <GitHubButton href="https://github.com/cristiansimion/micro-object-storage">GitHub</GitHubButton>
                </Small>
              </div>
            </DemoBox>
          </VerticalMiddle>
          <VerticalMiddle className="col-lg-6 col-md-6 col-xs-12" style={{ margin: '0 auto' }}>
            <DemoBox>
              <div>
                <H3>WordPress Plugin</H3>
                <div><strong>Hubspot Contact Sync</strong> on user registration. This plugin makes use of some basic wordpress features.</div>
                <Small>
                  {/* <DemoButton href="#">Demo</DemoButton> */}
                  <GitHubButton href="https://github.com/cristiansimion/hubspot-contact-sync" target="_blank">GitHub</GitHubButton>
                </Small>
              </div>
            </DemoBox>
          </VerticalMiddle>
        </div>
      </Container>
    </HeroSection>
  );
};

export default Contributions;
