import React from 'react';
import styled from 'styled-components';

const ContainerWrapper = styled.div`
  max-width: ${({ theme }) => theme.container.maxWidth};
  align-items: ${({ valign }) =>
    (valign === 'bottom') ? 'flex-end'
      : (valign === 'top') ? 'flex-start'
        : (valign === 'middle') ? 'center' : valign || null};
  width: ${({ theme }) => theme.container.width};
  margin: ${({ theme }) => theme.container.margin};
  padding: ${({ theme }) => theme.container.padding};
  &.row {
    margin: ${({ theme }) => theme.container.margin};
  }
  @media (max-width: 520px) {
    flex-direction: column-reverse;
  }
`;
const Container = props => {
  return (
    <ContainerWrapper {...props}/>
  );
};

export default Container;
