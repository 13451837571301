import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.div`
  & svg {
    font-size: ${({ size }) => size || null};
    margin-left: ${({ marginLeft }) => marginLeft || null};
    margin-right: ${({ marginRight }) => marginRight || null};
    margin-top: ${({ marginTop }) => marginTop || null};
    margin-bottom: ${({ marginBottom }) => marginBottom || null};
  }
`;

const Icon = (props) => {
  return (
    <StyledIcon {...props}/>
  );
};

export default Icon;
