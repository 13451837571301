import React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img`
  width: ${({ width }) => width || '100%'};
  display: ${({ theme }) => theme.elements.general.image.display};
  display: ${({ isVisible }) => isVisible === false ? 'none' : null};
`;

const Image = (props) => {
  return (
    <StyledImg {...props}/>
  );
};

export default Image;
