import React from 'react';
import styled from 'styled-components';

const AlignCenter = styled.div`
  text-align: ${({ direction }) => direction || null};
`;

const Align = (props) => {
  return (
    <AlignCenter {...props}/>
  );
};

export default Align;
