import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ToggleContainer = styled.div`
  cursor: pointer;
  user-select: none;
`;

const Toggle = styled.div`
  position: relative;
  width: 68px;
  height: 38px;
  margin: 0 auto 0 40px;
  border-radius: 40px;

  & input[type="checkbox"] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  & > span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 1;
    background-color: ${({ theme }) => theme.elements.modeToggle.backgroundColor};
    border: ${({ theme }) => theme.elements.modeToggle.border};
    box-sizing: border-box;
    border-radius: 40px;
    transition: 0.2s ease background-color, 0.2s ease opacity;
  }

  & > span:before,
  & > span:after {
    content: '';
    position: absolute;
    top: 7px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transition: 0.5s ease transform, 0.2s ease background-color;
  }

  & > span:before {
    background-color: #4154f1;
    transform: translate(-58px, 0px);
    z-index: 1;
  }

  & > span:after {
    background-color: #fff;
    transform: translate(8px, 0px);
    z-index: 0;
  }

  & input[type="checkbox"]:checked + span {
    background-color: #171924;
  }

  & input[type="checkbox"]:active + span {
    opacity: 0.5;
  }

  & input[type="checkbox"]:checked + span:before {
    background-color: #171924;
    transform: translate(34px, -4px);
  }

  & input[type="checkbox"]:checked + span:after {
    background-color: #ccc;
    transform: translate(39px, 0px);
  }

  & > div {
    z-index: 3;
    color: ${({ theme }) => theme.elements.modeToggle.color};
    display: block;
    position: absolute;
    font-size: 12px;
    top: 10px;
    left: -10px;
    transform: translate(-100%,0);
  }

  & > div > span {
    color: #fff;
    padding: 2px 4px;
    margin-left: 4px;
    background: #444;
    white-space: nowrap;
    border-radius: 4px;
  }
`;

const ToggleButton = (props) => {
  const { darkModeEnabled, changeMode } = props;

  const handleKeyUp = (event) => {
    if (event.keyCode === 77 && event.ctrlKey) {
      changeMode();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyUp, { once: false });
    return () => {
      window.removeEventListener('keydown', handleKeyUp);
    };
  }, [handleKeyUp]);

  return (
    <ToggleContainer>
      <Toggle>
        <input type="checkbox" onChange={changeMode} checked={darkModeEnabled} />
        <span />
        <div>press<span>Ctrl + M</span></div>
      </Toggle>
    </ToggleContainer>
  );
};

ToggleButton.propTypes = {
  darkModeEnabled: PropTypes.bool.isRequired,
  changeMode: PropTypes.func.isRequired,
};

export default ToggleButton;
