import React from 'react';
import ToggleButton from './buttons/toggle';
import { THEME_DARK_MODE_ID, THEME_NORMAL_MODE_ID } from '../../constants';
import { useSelector } from 'react-redux';
import { useTheme } from '../../theme/useTheme';
import { SELECT_THEME_MODE } from '../../store/reducers/theme';
import PropTypes from 'prop-types';

const ButtonToggleMode = (props) => {
  const theme = useSelector(SELECT_THEME_MODE);
  const { setThemeMode } = useTheme();

  const invertMode = theme === THEME_DARK_MODE_ID
    ? THEME_NORMAL_MODE_ID
    : THEME_DARK_MODE_ID;

  return (
    <ToggleButton darkModeEnabled={theme === THEME_DARK_MODE_ID}
      changeMode={() => setThemeMode(invertMode)} {...props} />
  );
};

ButtonToggleMode.propTypes = {
  props: PropTypes.object,
};

export default ButtonToggleMode;
