import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import Select from './select';
import TextArea from './textarea';

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const { data } = this.state;
    const options = { abortEarly: false };

    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (const item of error.details) {
      errors[item.path] = item.message;
    }

    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    this.doSubmit();
  };

  handleOnChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({
      data,
      errors,
    });
  };

  renderButton = (label) => {
    return (
      <React.Fragment>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={this.validate()}
        >
          {label}
        </button>
      </React.Fragment>
    );
  };

  renderInput = (name, type = 'text', label = null, placeholder = null) => {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        value={data[name]}
        label={label}
        error={errors[name]}
        placeholder={placeholder}
        type={type}
        onChange={this.handleOnChange}
      />
    );
  };

  renderTextArea = (name, label = null, placeholder = null) => {
    const { data, errors } = this.state;
    return (
      <TextArea
        name={name}
        value={data[name]}
        label={label}
        temp={placeholder}
        error={errors[name]}
        onChange={this.handleOnChange}
      />
    );
  };

  renderSelect = (name, options, label = null, placeholder = null) => {
    const { data, errors } = this.state;
    return (
      <Select
        options={options}
        label={label}
        name={name}
        error={errors[name]}
        value={data[name]}
        placeholder={placeholder}
        onChange={this.handleOnChange}
      />
    );
  }
}

export default Form;
