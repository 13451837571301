import React from 'react';
import styled from 'styled-components';

const StyledH2 = styled.h2`
  display:  ${({ theme }) => theme.elements.general.h2.display};
  font-family:  ${({ theme }) => theme.elements.general.h2.fontFamily};
  line-height:  ${({ theme }) => theme.elements.general.h2.lineHeight};
  text-align: ${({ align }) => align || null};
  /** Only if hero={true} **/
  margin: ${({ hero, theme }) => hero && theme.elements.home.hero.h2.margin};
  font-size: ${({ hero, theme }) => hero ? theme.elements.home.hero.h2.fontSize : theme.elements.general.h2.fontSize};
  font-weight: ${({ hero, theme }) => hero && theme.elements.home.hero.h2.fontWeight};
  color: ${({ hero, theme }) => hero && theme.elements.home.hero.h2.color}
`;

const H2 = (props) => {
  return (
    <StyledH2 {...props} />
  );
};

export default H2;
