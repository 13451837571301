import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
// Components
import App from './App';
import storeConfig from './store/configureStore';

import reportWebVitals from './reportWebVitals';
// CSS
import 'flexboxgrid/css/flexboxgrid.css';
import './index.css';

const store = storeConfig();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App/>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
