import React from 'react';
import Container from '../../../components/common/container';
import H5 from '../../../components/common/headings/h5';
import H1 from '../../../components/common/headings/h1';
import H2 from '../../../components/common/headings/h2';
import Small from '../../../components/common/small';
import Align from '../../../components/common/alignText';
import ButtonCTA from '../../../components/common/buttons/buttonCTA';
import Icon from '../../../components/common/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Image from '../../../components/common/image';
import { heroImg } from '../../../components/constants/images';
import HeroSection from '../../../components/common/heroSection';
import { useTheme } from '../../../theme/useTheme';
import styled from 'styled-components';

const HeroSectionStyled = styled(HeroSection)`
  padding: 20px;
`;

const HomeHeroSection = () => {
  const { themeObject: currentTheme } = useTheme();
  const { background, backgroundAttachment } = currentTheme.elements.home.hero;

  return (
    <HeroSectionStyled valign="middle" background={background} backgroundAttachment={backgroundAttachment}>
      <Container className="row" valign="middle">
        <div className="col-lg-6 col-xs-12">
          <H5 align="center">SHAPE TECHNOLOGY</H5>
          <H1 align="center" hero={true}>Developing</H1>
          <H2 align="center" hero={true}>Creating Software is what I do</H2>
          <Small align="center">WordPress, PHP, React, NodeJS</Small>
          <Align direction="center">
            <ButtonCTA align="center" to="/contact" style={{ marginTop: 30 }}>
              Get in Touch
              <Icon marginLeft="10px" size="22px">
                <FontAwesomeIcon icon={faCaretRight}/>
              </Icon>
            </ButtonCTA>
          </Align>
        </div>
        <div className="col-lg-6 col-xs-12">
          <Image src={heroImg}/>
        </div>
      </Container>
    </HeroSectionStyled>
  );
};

export default HomeHeroSection;
