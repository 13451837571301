import { createSelector, createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'youtubeOverlay',
  initialState: {
    show: false,
    id: '',
  },
  reducers: {
    OPEN_OVERLAY: (youtubeOverlay) => {
      youtubeOverlay.show = false;
      youtubeOverlay.id = null;
    },
    CLOSE_OVERLAY: (youtubeOverlay, action) => {
      youtubeOverlay.show = true;
      youtubeOverlay.id = action.payload.id;
    },
  },
});

export const SELECT_OVERLAY = createSelector(
  state => state.youtubeOverlay,
  youtubeOverlay => youtubeOverlay,
);

export const { CLOSE_OVERLAY, OPEN_OVERLAY } = slice.actions;
export default slice.reducer;
