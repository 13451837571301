import { combineReducers } from 'redux';
import header from './reducers/header';
import device from './reducers/device';
import theme from './reducers/theme';
import youtubeOverlay from './reducers/overlay';

export default combineReducers({
  device,
  theme,
  header,
  youtubeOverlay,
});
