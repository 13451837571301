import logoLight from '../../images/logo_light.png';
import logoDark from '../../images/logo_dark.png';
import footerBg from '../../images/footer_bg.png';
import heroImage from '../../images/hero.png';
import dannyLight from '../../images/danny_splash_light.png';
import dannyDark from '../../images/danny_splash_dark.png';
import curvyBgLight from '../../images/curvybg.png';
import curvyBgDark from '../../images/curvybg_dark.png';

export const curvyBackground = curvyBgLight;
export const curvyBackgroundDark = curvyBgDark;
export const logoURL = logoLight;
export const logoURLDark = logoDark;
export const heroImg = heroImage;
export const dannyInnySplash = dannyLight;
export const dannyInnySplashDark = dannyDark;
export const footerMaps = footerBg;
