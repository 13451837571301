import React from 'react';
import styled from 'styled-components';

const StyledH5 = styled.h5`
  display: ${({ theme }) => theme.elements.general.h5.display};
  color: ${({ theme }) => theme.elements.general.h5.color};
  margin-top: ${({ theme }) => theme.elements.general.h5.marginTop};
  margin-bottom: ${({ theme }) => theme.elements.general.h5.marginBottom};
  font-family: ${({ theme }) => theme.elements.general.h5.fontFamily};
  font-size: ${({ theme }) => theme.elements.general.h5.fontSize};
  line-height: ${({ theme }) => theme.elements.general.h5.lineHeight};
  font-weight: ${({ theme }) => theme.elements.general.h5.fontWeight};
  text-align: ${({ align }) => align || null};
`;

const H5 = (props) => {
  return (
    <StyledH5 {...props} />
  );
};

export default H5;
