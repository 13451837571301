import { LOCAL_STORAGE_THEME_KEY, THEME_DEFAULT_MODE } from '../constants';

export const setToLS = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = key => {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }

  return '';
};

export const getThemeModeFromLS = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);

  // TODO: implement localStorage theme entry validation.
  // Application might crash if you put random shit in "theme" in localStorage.

  if (value) {
    return JSON.parse(value);
  }

  return THEME_DEFAULT_MODE;
};
