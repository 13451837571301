import React from 'react';
import styled from 'styled-components';
import { logoURL, logoURLDark } from './constants/images';
import { BsChevronRight } from 'react-icons/bs';
import { SiGithub, SiTwitter, SiLinkedin } from 'react-icons/si';
import Container from './common/container';
import { Link, NavLink } from 'react-router-dom';
import H4 from './common/headings/h4';
import Align from './common/alignText';
import ButtonCTA from './common/buttons/buttonCTA';
import VAlign from './common/valign';
import { THEME_DARK_MODE_ID, THEME_DEFAULT_MODE } from '../constants';
import { useTheme } from '../theme/useTheme';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.elements.footer.background};
  background-size: contain;
  border-top: 1px solid #e1ecff;
  border-bottom: 1px solid #e1ecff;
  padding: 60px 0 30px 0;
  transition: ${({ theme }) => theme.general.transitions};

  & div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Logo = styled.img`
  max-width: 192px;
`;

const FooterInfo = styled.p`
  color: ${({ theme }) => theme.elements.footer.color};
  transition: ${({ theme }) => theme.general.transitions};
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Noto Sans", sans-serif;
`;

const SocialLinks = styled.div`
  margin-top: 1rem !important;
  transition: ${({ theme }) => theme.general.transitions};

  & a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
  }

  & svg {
    transition: ${({ theme }) => theme.general.transitions};
    color: ${({ theme }) => theme.elements.general.link.color};
  }

  & a:hover > svg {
    color: ${({ theme }) => theme.elements.general.linkActive.color};
  }
`;

const StyledList = styled.ul`
  font-size: 14px;
  margin: 0;
  padding: 0;

  & li {
    list-style-type: none;
    padding: 10px 0;
    display: flex;
    align-items: center;
  }

  & li i {
    padding-right: 2px;
    color: ${({ theme }) => theme.elements.general.linkActive.color};
    font-size: 12px;
    line-height: 0;
  }

  & li a {
    color: ${({ theme }) => theme.elements.general.link.color};
    transition: 0.3s;
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
  }

  & li a:hover {
    color: ${({ theme }) => theme.elements.general.linkActive.color};
  }
`;

const FooterH4 = styled(H4)`
  margin: 0;
`;

const Copyright = styled.div`
  background: ${({ theme }) => theme.elements.copyright.background};
  color: ${({ theme }) => theme.elements.copyright.color};
  transition: ${({ theme }) => theme.general.transitions};
  padding: 30px 10px 30px;
  text-align: center;
  font-size: 14px;
`;

const year = new Date().getFullYear();

const Footer = () => {
  const { theme } = useTheme();

  return (
    <React.Fragment>
      <Wrapper className="row">
        <Container margin="0 auto" className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-xs-12" style={{ marginBottom: '20px' }}>
              <NavLink to="/">
                <Logo style={{ display: (THEME_DEFAULT_MODE === theme) ? 'block' : 'none' }} src={logoURL}/>
                <Logo style={{ display: (THEME_DARK_MODE_ID === theme) ? 'block' : 'none' }} src={logoURLDark}/>
              </NavLink>
              <FooterInfo>Creating software, enhancing automation, integrating 3rd-party software, refactoring and
              designing platform architectures is what helps companies get to the next level. Passion, hard work,
              experience and ownership is what gets tasks done.</FooterInfo>
              <div>
                <SocialLinks>
                  <a href="https://github.com/cristiansimion/" target="_blank" rel="noreferrer"><SiGithub/></a>
                  <a href="https://twitter.com/cristiansimion1" target="_blank" rel="noreferrer"><SiTwitter/></a>
                  <a href="https://www.linkedin.com/in/cristiansimion/" target="_blank" rel="noreferrer"><SiLinkedin/></a>
                </SocialLinks>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-xs-6">
              <FooterH4>Navigation</FooterH4>
              <StyledList>
                <li><i><BsChevronRight/></i> <Link to="/">Home</Link></li>
                <li><i><BsChevronRight/></i> <Link to="/work/samples">Work Samples</Link></li>
                <li><i><BsChevronRight/></i> <Link to="/contact">Contact</Link></li>
                <li><i><BsChevronRight/></i> <Link to="/terms">Terms of service</Link></li>
                <li><i><BsChevronRight/></i> <Link to="/privacy-policy">Privacy policy</Link></li>
              </StyledList>
            </div>
            <div className="col-lg-2 col-md-6 col-xs-6">
              <FooterH4>USEFUL LINKS</FooterH4>
              <StyledList>
                <li><i><BsChevronRight/></i> <a href="https://github.com/cristiansimion/" target="_blank" rel="noreferrer">GitHub</a></li>
                <li><i><BsChevronRight/></i> <a href="https://bitbucket.org/cristiansimion1/" target="_blank" rel="noreferrer">BitBucket</a></li>
                <li><i><BsChevronRight/></i> <a href="https://www.linkedin.com/in/cristiansimion/" target="_blank" rel="noreferrer">Linkedin</a></li>
                <li><i><BsChevronRight/></i> <a href="https://twitter.com/cristiansimion1" target="_blank" rel="noreferrer">Twitter</a></li>
              </StyledList>
            </div>
            <div className="col-lg-3 col-md-12  col-xs-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <VAlign align="middle">
                <Align direction="center" style={{ width: '100%' }}>
                  <ButtonCTA to="/contact">Get in Touch</ButtonCTA>
                </Align>
              </VAlign>
            </div>
          </div>
        </Container>
      </Wrapper>
      <Copyright>
        <Container margin="0 auto" className="container">
          <div className="copyright">Copyright © 2012-{year} <strong><span>Cristian Simion</span></strong>. All Rights Reserved.</div>
        </Container>
      </Copyright>
    </React.Fragment>
  );
};

export default Footer;
