import React from 'react';
import Header from './components/header';
import Content from './components/content';
import { useTheme } from './theme/useTheme';
import { deviceModeToStore } from './theme/device';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './theme/globalStyles';
import Footer from './components/footer';
import { ScrollToTopControlller } from './utils/scrollToTopController';

const App = () => {

  // set Desktop/Tablet/Mobile mode in store
  deviceModeToStore();
  // 3: Get the selected theme, font list, etc.
  const { themeObject, themeLoaded } = useTheme();

  return (
    <>
      {
        themeLoaded && <ThemeProvider theme={ themeObject }>
          <GlobalStyles />
          <ScrollToTopControlller/>
          <Header />
          <Content />
          <Footer />
        </ThemeProvider>
      }
      {
        !themeLoaded && <React.Fragment>
          <h3 style={{ textAlign: 'center' }}>Loading theme please wait</h3>
        </React.Fragment>
      }
    </>
  );
};

export default App;
