import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';
import H3 from '../../../components/common/headings/h3';
import VAlign from '../../../components/common/valign';

import labsterImg from '../../../images/roll/labster.png';
import codeableImg from '../../../images/roll/codeable.png';
import miraseeImg from '../../../images/roll/mirasee.png';
import discoverhealingImg from '../../../images/roll/discoverhealing.png';
import morningcoachImg from '../../../images/roll/morningcoach.png';
import liquidwordpressImg from '../../../images/roll/liquidwordpress.png';
import donedealImg from '../../../images/roll/donedeal.png';
import smuklivingImg from '../../../images/roll/smuk.png';
import cambridgeImg from '../../../images/roll/cmg.png';
import mizpahImg from '../../../images/roll/mizpah.png';
import myplatesImg from '../../../images/roll/plates.png';
import tctprojectImg from '../../../images/roll/tctproject.png';
import growbycodeImg from '../../../images/roll/growbycode.png';
import gammasourceImg from '../../../images/roll/gammasource.png';
import powerpointgraphicsImg from '../../../images/roll/powerpointgraphics.png';
import powerspacesImg from '../../../images/roll/powerspaces.png';

const StyledLink = styled.a`
  user-select: none;
  outline-offset: 0;
  display: flex;

  &:focus {
    outline-offset: 0 !important;
  }

  & img {
    filter: grayscale(100%);
    opacity: 0.55;
    user-select: none;
    max-width: 100%;
    transition: ${({ theme }) => theme.general.transitions};
  }
  & img:hover {
    filter: grayscale(0%);
    opacity: 1;
  }

  & img:focus {

    outline-offset: 0 !important;
  }
`;

const Wrapper = styled.div`
  padding: 0;
  background: #f6f9ff;
  border-top: 1px solid #e1ecff;
  @media (max-width: 520px) {
    display: none;
  }
`;

const StyledCarousel = styled(Carousel)`
  & .rec-carousel-item {
    display: flex;
  }

  & .rec-carousel-item > .rec-item-wrapper {
    display: flex;
    align-items: center;
  }
  & button {
    user-select: none;
  }
  & button.rec-arrow,
  & button:disabled {
    color: #ccc;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  & button.rec-arrow:hover,
  & button.rec-arrow:focus,
  & button.rec-arrow:active{
    color: #000;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  & button:disabled:hover {
    color: #ccc;
  }
  & .rec-item-wrapper {
    padding: 4px;
  }
`;

const StyledH3 = styled(H3)`
  margin: 0 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: #a9a9a9;
`;

const VerticalAlign = styled(VAlign)`
  position: relative;
  background: #444;
  flex-basis: 180px;
  & h3 {
    color: #fff;
    user-select: none;
  }
`;

const items = [
  {
    name: 'Labster',
    url: 'https://www.labster.com',
    image: labsterImg,
  },
  {
    name: 'Codeable',
    url: 'https://codeable.io/developers/cristian-simion/',
    image: codeableImg,
  },
  {
    name: 'Mirasee',
    url: 'https://mirasee.com',
    image: miraseeImg,
  },
  {
    name: 'Discover Healing',
    url: 'https://discoverhealing.com/',
    image: discoverhealingImg,
  },
  {
    name: 'MorningCoach',
    url: 'https://morningcoach.com',
    image: morningcoachImg,
  },
  {
    name: 'Liquid WordPress',
    url: 'https://liquidwordpress.com/',
    image: liquidwordpressImg,
  },
  {
    name: 'DoneDeal Website',
    url: 'https://donedealwebsite.com/',
    image: donedealImg,
  },
  {
    name: 'SmukLiving',
    url: 'https://www.smuk.com.my/',
    image: smuklivingImg,
  },
  {
    name: 'Cambridge Management Group',
    url: 'https://cmg625.com/',
    image: cambridgeImg,
  },
  {
    name: 'Mizpah',
    url: 'https://www.mizpah.com/',
    image: mizpahImg,
  },
  {
    name: 'MyPlates',
    url: 'https://www.myplates.io/',
    image: myplatesImg,
  },
  {
    name: 'TCT Project',
    url: 'https://www.tctproject.com/',
    image: tctprojectImg,
  },
  {
    name: 'GrowByCode',
    url: 'https://growbycode.com/',
    image: growbycodeImg,
  },
  {
    name: 'GammaSource',
    url: 'https://gammasource.com/',
    image: gammasourceImg,
  },
  {
    name: 'PowerPointGraphics',
    url: 'https://powerpointgraphics.com/',
    image: powerpointgraphicsImg,
  },
  {
    name: 'PowerSpaces',
    url: 'https://powerspaces.com/',
    image: powerspacesImg,
  },
];

const SitesRoll = () => {

  const [timeoutCallback, setTimeoutCallback] = useState(null);

  const reactItems = items.map(item => {
    return (
      <StyledLink key={item.name} href={item.url}>
        <img src={item.image}/>
      </StyledLink>
    );
  });

  const carouselRef = useRef(null);
  const autoPlaySpeed = 12000;
  const itemsPerPage = 8;

  useEffect(() => {
    return () => {
      if (timeoutCallback) {
        clearTimeout(timeoutCallback);
        setTimeoutCallback(null);
      }
    };
  }, []);

  const handleUpdate = (currentItem) => {
    if (currentItem.index === carouselRef.current.getNextItemIndex()) {
      setTimeoutCallback(setTimeout(() => {
        if (carouselRef.current) {
          carouselRef.current.goTo(0);
        }
      }, autoPlaySpeed));
    }
    return false;
  };

  return (
    <Wrapper>
      <div className="row">
        <VerticalAlign align="middle" className="col-lg-2 col-md-2 col-xs-12">
          <StyledH3>Worked on</StyledH3>
        </VerticalAlign>
        <div className="col-lg-10 col-md-10 col-xs-12" style={{ maxWidth: '100%' }}>
          <StyledCarousel
            ref={carouselRef}
            enableAutoPlay
            autoPlaySpeed={autoPlaySpeed}
            itemsToScroll={1}
            itemsToShow={itemsPerPage}
            pagination={false}
            enableMouseSwipe={false}
            onChange={handleUpdate}
          >
            {reactItems}
          </StyledCarousel>
        </div>
      </div>
    </Wrapper>
  );
};

export default SitesRoll;
