import { createSelector, createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'theme',
  initialState: {
    mode: 'light',
  },
  reducers: {
    SET_STORE_THEME: (theme, action) => {
      theme.mode = action.payload.mode;
    },
  },
});
export const SELECT_THEME_MODE = createSelector(
  state => state.theme,
  theme => theme.mode,
);
export const { SET_STORE_THEME } = slice.actions;
export default slice.reducer;
