import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  display: inline-flex;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: ${({ theme }) => theme.elements.mirasee.button.boxShadow};
  text-decoration: none;
  &:hover {
    background: #485092;
  }
`;

const Button = (props) => {
  return (
    <StyledLink {...props}/>
  );
};

export default Button;
