import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Page from './common/pageLinks';

const Content = () => {

  return (
    <div>
      <Switch>
        <Route exact={Page.privacyPolicy.exact} path={Page.privacyPolicy.path} render={() => Page.privacyPolicy.content} />
        <Route exact={Page.termsAndConditions.exact} path={Page.termsAndConditions.path} render={() => Page.termsAndConditions.content} />
        <Route exact={Page.workSamples.exact} path={Page.workSamples.path} render={() => Page.workSamples.content} />
        <Route exact={Page.contact.exact} path={Page.contact.path} render={() => Page.contact.content} />
        <Route exact={Page.home.exact} path={Page.home.path} render={() => Page.home.content} />
        <Route exact={Page.notFound.exact} path={Page.notFound.path} render={() => Page.notFound.content} />
        <Redirect to={Page.notFound.path} />
      </Switch>
    </div>
  );
};

export default Content;
