import React from 'react';
import styled from 'styled-components';
import useStoreSticky from '../utils/useStoreSticky';
import PropTypes from 'prop-types';

const Sticky = styled.div`
  position: fixed;
  width: 100%;
  top:0;
  left:0;
  transition: ${({ theme }) => theme.general.transitions};
  z-index: 20;
  & header {
    transition: ${({ theme }) => theme.general.transitions};
    background: ${({ isElementSticky, theme }) => (isElementSticky ? theme.header.sticky.background : null)};
    border-bottom: ${({ isElementSticky, theme }) => (isElementSticky ? theme.header.sticky.borderBottom : null)};
    box-shadow: ${({ isElementSticky, theme }) => (isElementSticky ? theme.header.sticky.shadow : null)};
  }
  @media (max-width: 520px) {
    background: ${({ theme }) => theme.general.colors.body};
  }
`;

const SetSticky = ({ children }) => {
  const { isSticky } = useStoreSticky();

  return (
    <Sticky isElementSticky={isSticky}>
      {children}
    </Sticky>
  );
};

SetSticky.propTypes = {
  children: PropTypes.object,
};

export default SetSticky;
