import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  display: ${({ theme }) => theme.elements.general.h1.display};
  font-family: ${({ theme }) => theme.elements.general.h1.fontFamily};
  line-height: ${({ theme }) => theme.elements.general.h1.lineHeight};
  text-align: ${({ align }) => align || null};
  /** Only if hero={true} **/
  margin: ${({ hero, theme }) => hero && theme.elements.home.hero.h1.margin};
  font-size: ${({ hero, theme }) => hero ? theme.elements.home.hero.h1.fontSize : theme.elements.general.h1.fontSize};
  font-weight: ${({ hero, theme }) => hero && theme.elements.home.hero.h1.fontWeight};
  color: ${({ hero, theme }) => hero && theme.elements.home.hero.h1.color}
`;

const H1 = (props) => {
  return (
    <StyledH1 {...props} />
  );
};

export default H1;
