import React, { useEffect, useState } from 'react';
import { DISABLE_STICKY, ENABLE_STICKY, SELECT_IS_HEADER_STICKY } from '../store/reducers/header';
import { useDispatch, useSelector } from 'react-redux';

const useStoreSticky = () => {
  const [sticky, setSticky] = useState(false);
  const isSticky = useSelector(SELECT_IS_HEADER_STICKY);
  const dispatch = useDispatch();

  const handleScroll = () => {
    const scrollY = window.pageYOffset;

    if (scrollY > 10) {
      if (!isSticky) {
        setSticky(true);
      }
    } else {
      if (isSticky) {
        setSticky(false);
      }
    }
  };

  useEffect(() => {
    if (!isSticky && sticky) {
      dispatch(ENABLE_STICKY());
    } else if (isSticky && !sticky) {
      dispatch(DISABLE_STICKY());
    }
  }, [sticky]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isSticky]);

  return { isSticky };
};

export default useStoreSticky;
