import React from 'react';
import styled from 'styled-components';

const Error = styled.div`
  color: ${({ theme }) => theme.elements.error.color};
  font-size: ${({ theme }) => theme.elements.error.fontSize};
  font-family: ${({ theme }) => theme.elements.error.fontFamily};
  margin-top: ${({ theme }) => theme.elements.error.marginTop};
`;

const ErrorNotice = (props) => {
  return (
    <Error {...props} />
  );
};

export default ErrorNotice;
