import React from 'react';
import Container from '../../../components/common/container';
import H3 from '../../../components/common/headings/h3';
import H2 from '../../../components/common/headings/h2';
import Small from '../../../components/common/small';
import Align from '../../../components/common/alignText';
import Icon from '../../../components/common/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import Image from '../../../components/common/image';
import { dannyInnySplash, dannyInnySplashDark } from '../../../components/constants/images';
import { useTheme } from '../../../theme/useTheme';
import H1 from '../../../components/common/headings/h1';
import Button from '../../../components/common/buttons/button';
import { THEME_DARK_MODE_ID } from '../../../constants';
import styled from 'styled-components';

const AboutSection = styled.div`
  background-color: ${({ theme }) => theme.elements.mirasee.backgroundColor};
  color: ${({ theme }) => theme.elements.mirasee.color};
  padding: 40px;
  font-family: 'Open Sans', sans-serif;
  & h2 {
    margin:0;
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.elements.mirasee.h2.color};
  }
  & h3 {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.elements.mirasee.h3.color};
    text-transform: uppercase;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  & p {
    font-family: 'Open Sans', sans-serif;
    margin: 15px 0 30px 0;
    font-size: 1rem;
    line-height: 24px;
  }
  & small {
    font-size: 80%;
  }
  & small > strong {
    color: ${({ theme }) => theme.elements.mirasee.small.color};
  }
`;

const StyledH1 = styled(H1)`
  margin-bottom: 60px;
  color: ${({ theme }) => theme.elements.home.hero.h1.color};
  transition: ${({ theme }) => theme.general.transitions};
`;

const FeaturedTestimonial = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.elements.about.background};
  transition: ${({ theme }) => theme.general.transitions};
  padding-top: 80px;
  padding-bottom: 120px;
  @media (max-width: 520px) {
    padding-top:0;
    padding-bottom:0;
  }
`;

const ContainerStyled = styled(Container)`
  @media (max-width: 520px) {
    flex-direction: row-reverse;
  }
`;

const HomeAboutSection = () => {
  const { theme } = useTheme();
  return (
    <React.Fragment>
      <FeaturedTestimonial>
        <ContainerStyled className="row" valign="middle">
          <StyledH1 align="center" className="col-lg-12 col-xs-12">Featured Testimonial</StyledH1>
          <div className="col-lg-6 col-xs-12" style={{ paddingLeft: 15, paddingRight: 15 }}>
            <AboutSection>
              <H3 align="center">MIRASEE</H3>
              <H2>Danny Iny</H2>
              <p>Worked as Lead Developer, integrating multiple 3rd-party APIs, systems architect and optimizing launching designs, which lead to tenfold growth within 2 years.</p>
              <Small>
                <strong>Techs used:</strong>
                <div>SOAP, XML-RPC, REST, jQuery, JavaScript, WordPress, PHP, MySQL, HTML, CSS</div>
              </Small>
              <Align direction="center">
                <Button href="https://www.youtube.com/watch?v=k2KznDRymQg" style={{ marginTop: 30 }} target="_blank" rel="noreferrer">
                Play video
                  <Icon marginLeft="10px" size="22px">
                    <FontAwesomeIcon icon={faPlayCircle}/>
                  </Icon>
                </Button>
              </Align>
            </AboutSection>
          </div>
          <div className="col-lg-6 col-xs-12">
            <Image isVisible={THEME_DARK_MODE_ID !== theme} src={dannyInnySplash} width="100%" />
            <Image isVisible={THEME_DARK_MODE_ID === theme} src={dannyInnySplashDark} width="100%" />
          </div>
        </ContainerStyled>
      </FeaturedTestimonial>
    </React.Fragment>
  );
};

export default HomeAboutSection;
