import _merge from 'lodash/merge';
import { curvyBackground, curvyBackgroundDark, footerMaps } from '../components/constants/images';

const light = {
  name: 'Light mode',
  container: {
    width: '100%',
    maxWidth: '1140px',
    margin: '0 auto',
    position: 'relative',
  },
  header: {
    sticky: {
      background: '#fff',
      borderBottom: 'none',
      shadow: '0px 2px 20px rgb(1 41 112 / 10%)',
    },
  },
  elements: {
    about: {
      background: '#fff',
    },
    contact: {
      background: '#f7f8fc',
      boxShadow: '0 0 46px 0 rgb(0 0 0 / 16%)',
      form: {
        label: {
          color: '#212529',
        },
        button: {
          boxShadow: '0 5px 30px rgb(65 84 241 / 40%)',
        },
        background: '#fff',
        color: 'inherit',
      },
      img: {
        filter: 'grayscale(1)',
      },
      link: {
        a: {
          color: '#4154f1',
        },
        h3: {
          color: '#222',
        },
      },
      linkActive: {
        a: {
          color: '#485092',
        },
        h3: {
          color: '#222',
        },
      },
      listItem: {
        background: '',
      },
      listItemActive: {
        background: '#d8d8d8',
      },
    },
    mirasee: {
      backgroundColor: '#f6f9ff',
      color: 'inherit',
      h2: {
        color: '#012970',
      },
      h3: {
        color: '#4154f1',
      },
      small: {
        color: '#444',
      },
      button: {
        boxShadow: '0 5px 30px rgb(65 84 241 / 40%)',
      },
    },
    forms: {
      label: {
        input: {
          fontFamily: '"Open Sans", sans-serif',
          display: 'inline-block',
          marginBottom: '.5rem',
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.5',
          color: '#212529',
        },
        select: {
          fontFamily: '"Open Sans", sans-serif',
          display: 'inline-block',
          marginBottom: '.5rem',
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.5',
          color: '#212529',
        },
      },
      group: {
        marginBottom: '1rem',
        display: 'block',
      },
      input: {
        display: 'block',
        width: '100%',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        lineHeight: '1.5',
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        overflow: 'visible',
        boxSizing: 'border-box',
      },
      select: {
        display: 'block',
        width: '100%',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        lineHeight: '1.5',
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        overflow: 'visible',
        boxSizing: 'border-box',
      },
    },
    general: {
      small: {
        fontSize: '80%',
        fontWeight: '400',
        fontFamily: '"Open Sans", sans-serif',
        display: 'block',
        color: 'inherit',
      },
      link: {
        color: '#000',
        opacity: 1,
        fontWeight: 600,
      },
      linkActive: {
        color: '#4154f1',
        opacity: 1,
        fontWeight: 700,
      },
      modeButton: {
        text: '#000',
        background: '#fff',
      },
      image: {
        display: 'block',
      },
      button: {
        text: '#013289',
        background: 'transparent',
        fontWeight: 600,
      },
      buttonHover: {
        text: '#4154f1',
        fontWeight: 700,
      },
      h1: {
        fontWeight: '700',
        fontSize: '3.5em',
        lineHeight: '1.2',
        display: 'block',
        fontFamily: '"Noto Sans", sans-serif',
      },
      h2: {
        fontWeight: '500',
        fontSize: '2.5em',
        lineHeight: '1.2',
        display: 'block',
        fontFamily: '"Noto Sans", sans-serif',
      },
      h3: {
        display: 'block',
        fontFamily: '"Noto Sans", sans-serif',
        fontWeight: '500',
        lineHeight: '1.2',
        marginBottom: '.5rem',
      },
      h4: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#012970',
        textTransform: 'uppercase',
        position: 'relative',
        paddingBottom: '12px',
      },
      h5: {
        display: 'block',
        color: '#444444',
        marginTop: '0',
        marginBottom: '.5rem',
        fontFamily: '"Noto Sans", sans-serif',
        fontSize: '1.25rem',
        lineHeight: '1.2',
        fontWeight: '500',
      },
    },
    home: {
      hero: {
        background: `url(${curvyBackground}) center center no-repeat`,
        backgroundAttachment: 'fixed',
        h1: {
          color: '#012970',
          fontWeight: 700,
          fontSize: '48px',
          margin: '0',
        },
        h2: {
          color: '#444444',
          fontWeight: 500,
          fontSize: '26px',
          margin: '15px 0 0 0',
        },
      },
    },
    error: {
      fontSize: '12px',
      color: '#ce1e1e',
      fontFamily: '"Open Sans", sans-serif',
      marginTop: '4px',
    },
    modeToggle: {
      color: '#000',
      backgroundColor: '#4154f1',
      border: '1px solid transparent',
    },
    footer: {
      background: `white url(${footerMaps}) no-repeat right top`,
      color: 'inherit',
    },
    copyright: {
      background: '#f6f9ff',
      color: 'inherit',
    },
  },
  general: {
    colors: {
      body: '#FFFFFF',
      text: '#000000',
    },
    body: {
      margin: '0',
    },
    borderBottom: '1px solid #dedede',
    transitions: '0.3s all linear',
  },
  font: 'Open Sans',
};

const dark = _merge({}, light, {
  name: 'Dark Mode',
  header: {
    sticky: {
      background: '#222531',
      borderBottom: '1px solid #323546',
      shadow: '0px 2px 20px rgb(1 41 112 / 10%)',
    },
  },
  elements: {
    about: {
      background: '#171924',
    },
    contact: {
      background: '#171924',
      boxShadow: 'none',
      form: {
        label: {
          color: '#fff',
        },
        button: {
          boxShadow: 'none',
        },
        background: '#222531',
        color: '#ccc',
      },
      img: {
        filter: 'grayscale(1) invert(1)',
      },
      link: {
        a: {
          color: '#6188ff',
        },
        h3: {
          color: '#ccc',
        },
      },
      linkActive: {
        a: {
          color: '#98caff',
        },
        h3: {
          color: '#ccc',
        },
      },
      listItem: {
        background: '',
      },
      listItemActive: {
        background: '#222531',
      },
    },
    mirasee: {
      backgroundColor: '#222531',
      color: '#fff',
      h2: {
        color: '#eee',
      },
      h3: {
        color: '#98caff',
      },
      small: {
        color: '#ccc',
      },
      button: {
        boxShadow: 'none',
      },
    },
    general: {
      small: {
        color: '#f3f1f1',
      },
      link: {
        color: '#6188ff',
      },
      linkActive: {
        color: '#98caff',
      },
      modeButton: {
        text: '#000',
        background: '#fff',
      },
      button: {
        text: '#013289',
        background: 'transparent',
      },
      buttonHover: {
        text: '#4154f1',
      },
      h1: {
        color: '#f3f1f1',
      },
      h2: {
        color: '#f3f1f1',
      },
      h3: {
        color: '#f3f1f1',
      },
      h4: {
        color: '#f3f1f1',
      },
      h5: {
        color: '#f3f1f1',
      },
    },
    home: {
      hero: {
        background: `url(${curvyBackgroundDark}) center center no-repeat`,
        backgroundAttachment: 'fixed',
        h1: {
          color: '#f3f1f1',
        },
        h2: {
          color: '#d4d4d4',
        },
      },
    },
    modeToggle: {
      color: '#fff',
      border: '1px solid #323546',
    },
    footer: {
      background: `#222531 url(${footerMaps}) no-repeat right top`,
      color: '#FFF',
    },
    copyright: {
      background: '#171924',
      color: '#f6f9ff',
    },
  },
  general: {
    colors: {
      body: '#171924',
      text: '#a1a7bb',
    },
    body: {
      margin: '0',
    },
    transitions: '0.3s all linear',
    borderBottom: '1px solid #323546',
  },
  font: 'Open Sans',
});

export const data = {
  light: light,
  dark: dark,
};
