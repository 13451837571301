import React from 'react';
import { logoURL, logoURLDark } from './constants/images';

import Menu from './menu';
import styled from 'styled-components';
import Container from './common/container';
import SetSticky from './setSticky';
import useStoreSticky from '../utils/useStoreSticky';
import { useTheme } from '../theme/useTheme';
import { THEME_DARK_MODE_ID, THEME_DEFAULT_MODE } from '../constants';
import { NavLink } from 'react-router-dom';

const LogoColumn = styled.div`
  margin: 0;
  flex-basis: auto;
  & img {
    max-width: 185px;
  }
  @media (max-width: 520px) {
    flex-basis: 100%;
    & img { 
      margin: 10px auto 0;
    }
  }
`;

const LogoSpacer = styled.div`
  position: relative;
  visibility: hidden;
  pointer-events: none;
  line-height: 0;
  z-index: -100;
  margin-top: ${({ sticky }) => sticky ? 24 : 30}px;
  margin-bottom: ${({ sticky }) => sticky ? 24 : 30}px;
  transition: ${({ theme }) => theme.general.transitions};
  & img {
    height: 24px;
    max-width: 185px;
  }
  & img:not([srcset]) {
    width: auto;
  }
`;

const HeaderWrap = styled.header`
  display: block;
`;

const Static = styled.div`
  position: relative;
  display: flex;
  float: none;
`;

const Row = styled.div`
  align-items: center;
  position: relative;
`;

const Header = () => {
  const { theme } = useTheme();
  const { isSticky } = useStoreSticky();

  return (
    <SetSticky>
      <HeaderWrap>
        <Container>
          <Row className="row">
            <LogoColumn className="col-lg-3 col-md-3 col-xs-12">
              <NavLink to={'/'}>
                <img style={{ display: (THEME_DEFAULT_MODE === theme) ? 'block' : 'none' }} src={logoURL} alt="Cristian Simion Logo" />
                <img style={{ display: (THEME_DARK_MODE_ID === theme) ? 'block' : 'none' }} src={logoURLDark} alt="Cristian Simion Logo" />
              </NavLink>
            </LogoColumn>
            <Static className="col-lg-9 col-md-9 col-xs-12">
              <Menu />
              <LogoSpacer sticky={isSticky}>
                <img style={{ display: (THEME_DEFAULT_MODE === theme) ? 'block' : 'none' }} src={logoURL} alt="Cristian Simion Logo" />
                <img style={{ display: (THEME_DARK_MODE_ID === theme) ? 'block' : 'none' }} src={logoURLDark} alt="Cristian Simion Logo" />
              </LogoSpacer>
            </Static>
          </Row>
        </Container>
      </HeaderWrap>
    </SetSticky>
  );
};

export default Header;
