import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTopControlller = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);

  // renders nothing, since nothing is needed
  return null;
};
