import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display:flex;
  align-items: center;
`;

const VAlign = (props) => {
  return (
    <StyledDiv {...props} />
  );
};

export default VAlign;
