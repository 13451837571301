import React from 'react';
import AboutSection from './sections/home/homeAbout';
import HeroSection from './sections/home/homeHero';
import ClientsCarousel from './sections/home/homeSitesRoll';

const PageHome = () => {
  return (
    <React.Fragment>
      <HeroSection />
      <AboutSection />
      <ClientsCarousel />
    </React.Fragment>
  );
};

export default PageHome;
