import React from 'react';
import styled from 'styled-components';

const StyledH4 = styled.h4`
  font-size: ${({ theme }) => theme.elements.general.h4.fontSize};
  font-weight: ${({ theme }) => theme.elements.general.h4.fontWeight};
  color: ${({ theme }) => theme.elements.general.h4.color};
  text-transform: ${({ theme }) => theme.elements.general.h4.textTransform};
  position: ${({ theme }) => theme.elements.general.h4.position};
  padding-bottom: ${({ theme }) => theme.elements.general.h4.paddingBottom};
`;

const H4 = (props) => {
  return (
    <StyledH4 {...props} />
  );
};

export default H4;
