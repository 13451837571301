import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SELECT_THEME_MODE, SET_STORE_THEME } from '../store/reducers/theme';
import { themeMode } from '../utils/theme';
import { setToLS, getThemeModeFromLS } from '../utils/storage';
import { LOCAL_STORAGE_THEME_KEY } from '../constants';

export const useTheme = () => {
  const dispatch = useDispatch();

  // DEFAULTS BEFORE STORE
  const theme = useSelector(SELECT_THEME_MODE);
  const localThemeMode = getThemeModeFromLS();

  const [themeLoaded, setThemeLoaded] = useState(false);
  const [themeObject, setThemeObject] = useState(themeMode(localThemeMode));

  const setThemeMode = mode => {
    setToLS(LOCAL_STORAGE_THEME_KEY, mode);
    setModeToStore(mode);
  };

  const setModeToStore = mode => {
    if (themeMode(mode) !== 'undefined') {
      if (theme !== mode) { dispatch(SET_STORE_THEME({ mode })); }
    }
  };

  // componentDidMount
  useEffect(() => {
    setThemeMode(localThemeMode);
    setThemeObject(themeMode(theme));
    setThemeLoaded(true);
  }, []);

  // componentWillUpdate
  useEffect(() => {
    setThemeObject(themeMode(theme));
  }, [theme]);

  return { theme, themeObject, themeLoaded, setThemeMode };
};
