import { createSelector, createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'header',
  initialState: {
    menuList: [],
    isSticky: false,
    scrollY: 0,
    classes: ['header', 'fixed-top'],
  },
  reducers: {
    ENABLE_STICKY: (header) => {
      header.isSticky = true;
    },

    DISABLE_STICKY: (header) => {
      header.isSticky = false;
    },
  },
});

export const SELECT_IS_HEADER_STICKY = createSelector(
  state => state.header,
  header => header.isSticky,
);

export const { ENABLE_STICKY, DISABLE_STICKY } = slice.actions;
export default slice.reducer;
