import React from 'react';
import styled from 'styled-components';
import ErrorNotice from '../notices/error';
import PropTypes from 'prop-types';

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.elements.forms.group.marginBottom};
  display: ${({ theme }) => theme.elements.forms.group.display};

  & label {
    font-family: ${({ theme }) => theme.elements.forms.label.select.fontFamily};
    display: ${({ theme }) => theme.elements.forms.label.select.display};
    margin-bottom: ${({ theme }) => theme.elements.forms.label.select.marginBottom};
    font-size: ${({ theme }) => theme.elements.forms.label.select.fontSize};
    font-weight: ${({ theme }) => theme.elements.forms.label.select.fontWeight};
    line-height: ${({ theme }) => theme.elements.forms.label.select.lineHeight};
    color: ${({ theme }) => theme.elements.forms.label.select.color};
  }
`;

const FormControlTextArea = styled.textarea`
  display: ${({ theme }) => theme.elements.forms.input.display};
  width: ${({ theme }) => theme.elements.forms.input.width};
  padding: ${({ theme }) => theme.elements.forms.input.padding};
  font-size: ${({ theme }) => theme.elements.forms.input.fontSize};
  line-height: ${({ theme }) => theme.elements.forms.input.lineHeight};
  color: ${({ theme }) => theme.elements.forms.input.color};
  background-color: ${({ theme }) => theme.elements.forms.input.backgroundColor};
  background-clip: ${({ theme }) => theme.elements.forms.input.backgroundClip};
  border: ${({ theme }) => theme.elements.forms.input.border};
  border-radius: ${({ theme }) => theme.elements.forms.input.borderRadius};
  transition: ${({ theme }) => theme.elements.forms.input.transition};
  overflow: ${({ theme }) => theme.elements.forms.input.overflow};
  box-sizing: ${({ theme }) => theme.elements.forms.input.boxSizing};
`;

const TextArea = ({ name, label, temp, error, ...rest }) => {
  return (
    <FormGroup>
      {label && <label htmlFor={name}>{label}</label>}
      <FormControlTextArea
        name={name}
        id={name}
        placeholder={temp}
        {...rest}
      />
      {error && <ErrorNotice>{error}</ErrorNotice>}
    </FormGroup>
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  temp: PropTypes.string,
  error: PropTypes.string,
};

export default TextArea;
