import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Open Sans',sans-serif;
    margin: ${({ theme }) => theme.general.body.margin};
    background: ${({ theme }) => theme.general.colors.body};
    transition: ${({ theme }) => theme.general.transitions};
  }
  a {
    color: ${({ theme }) => theme.elements.general.link.color};
  }
  .row {
    margin: 0;
  }
  div[class^="col-"] {
    padding:0;
  }
`;
