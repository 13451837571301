import React from 'react';
import styled from 'styled-components';

const StyledSmall = styled.small`
  font-size:  ${({ theme }) => theme.elements.general.small.fontSize};
  font-weight:  ${({ theme }) => theme.elements.general.small.fontWeight};
  font-family:  ${({ theme }) => theme.elements.general.small.fontFamily};
  display:  ${({ theme }) => theme.elements.general.small.display};
  color:  ${({ theme }) => theme.elements.general.small.color};
  text-align: ${({ align }) => align || null};
`;

const Small = (props) => {
  return (
    <StyledSmall {...props} />
  );
};

export default Small;
