import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as PageLinks from './common/pageLinks';
import { useSelector } from 'react-redux';
import ButtonToggleMode from './common/buttonToggleMode';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  display: flex;
  position: static;
  flex-grow: 1;
  align-items: center;
`;
const MenuContainer = styled.div`
  display: block;
`;
const MenuList = styled.ul`
  position: static;
  display: flex;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  list-style: none outside none;
`;
const MenuListItem = styled.li`
  display: flex;

  & a {
    display: block;
    padding: 10px 18px;
    margin: 0 1px;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.elements.general.link.fontWeight};
    color: ${({ theme }) => theme.elements.general.link.color};
  }

  & a.active {
    color: ${({ theme }) => theme.elements.general.linkActive.color};
    font-weight: 700;
  }

  & a:hover {
    color: ${({ theme }) => theme.elements.general.linkActive.color};
    font-weight: ${({ theme }) => theme.elements.general.linkActive.fontWeight};
  }

  @media (max-width: 1199px) {
    & a {
      padding: 20px;
      width: 100%;
    }
  }
`;

const ToggleArea = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  margin: 0 14px;
  height: 100%;
  align-items: center;
`;

const MenuLinks = [
  PageLinks.home,
  PageLinks.workSamples,
  PageLinks.contact,
];

const FoldMobile = styled.ul`
  position: absolute;
  background: ${({ theme }) => theme.general.colors.body};
  padding: 0;
  width: 100%;
  top: 100%;
  left: 0;
  margin: 0;
  transition: ${({ theme }) => theme.general.transitions};
  height: ${({ toggled }) => (toggled) ? '100vh' : 0};
  overflow: ${({ toggled }) => (toggled) ? 'auto' : 'hidden'};
  & li {
    padding:20px;
    border-top: ${({ theme }) => theme.general.borderBottom};
    list-style-type: none;
  }
  @media (max-width: 1199px) {
    & li {
      padding:0;
    }
  }
`;

const ResumeLink = styled.a`
  &.download {
    background: #4154f1;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    transition: ${({ theme }) => theme.general.transitions};
  }
  &.download:hover {
    background: #485092;
    color: #fff;
    font-weight: 500;
  }
  @media (max-width: 1199px) {
    &.download {
      border-radius: 0;
    }
  }
`;

const BurgerContainer = styled.a`
  font-size: 2em;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:active {
    color: #444;
  }
`;

const Menu = () => {
  const device = useSelector(state => state.device);
  const [toggle, setToggle] = useState(false);

  const handleToggle = (event, toggle) => {
    event.preventDefault();
    setToggle(!toggle);
  };

  return (
    <Wrapper>
      {(device.desktop || device.tablet) && <MenuList>
        {MenuLinks.map(link => (
          <MenuListItem key={link.path}>
            <NavLink exact to={link.path}>{link.label}</NavLink>
          </MenuListItem>
        ))}
        <MenuListItem>
          <ResumeLink className="download" href="https://drive.google.com/uc?export=download&id=1vLXgS772XHqi3NSGy9_2kIeLYlAbatAF" target="_blank" rel="noreferrer">Download Resume</ResumeLink>
        </MenuListItem>
      </MenuList>}
      {device.mobile && <MenuContainer>
        <BurgerContainer href="#" onClick={(e) => handleToggle(e, toggle)}><FontAwesomeIcon icon={faBars}/></BurgerContainer>
        <FoldMobile toggled={toggle}>
          {MenuLinks.map(link => (
            <MenuListItem key={link.path}>
              <NavLink exact to={link.path} onClick={() => setToggle(!toggle)}>{link.label}</NavLink>
            </MenuListItem>
          ))}
          <MenuListItem>
            <ResumeLink className="download" href="https://drive.google.com/uc?export=download&id=1vLXgS772XHqi3NSGy9_2kIeLYlAbatAF" target="_blank" rel="noreferrer">Download Resume</ResumeLink>
          </MenuListItem>
        </FoldMobile>
      </MenuContainer>}
      <ToggleArea>
        <ButtonToggleMode label="Mode"/>
      </ToggleArea>

    </Wrapper>
  );
};

export default Menu;
