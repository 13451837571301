import React from 'react';
import Contributions from './sections/worksamples/contributions';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;

const WorkSamples = () => {
  return (
    <Wrapper>
      <Contributions />
    </Wrapper>
  );
};

export default WorkSamples;
