import { SET_DESKTOP, SET_MOBILE } from '../store/reducers/device';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const deviceModeToStore = () => {
  const [isUpdateScheduled, scheduleUpdate] = useState(false);
  const dispatch = useDispatch();
  const device = useSelector(state => state.device);

  const setStoreToCurrentDeviceType = () => {
    if (!device.mobile && window.innerWidth < 1200) {
      dispatch(SET_MOBILE());
    } else if (!device.desktop && window.innerWidth >= 1200) {
      dispatch(SET_DESKTOP());
    }
  };

  const throttledHandleWindowResize = () => {
    if (isUpdateScheduled) return;

    scheduleUpdate(true);

    setTimeout(() => {
      setStoreToCurrentDeviceType();
      scheduleUpdate(false);
    }, 200);
  };

  useEffect(() => {
    setStoreToCurrentDeviceType();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', throttledHandleWindowResize);
    return () => window.removeEventListener('resize', throttledHandleWindowResize);
  }, [isUpdateScheduled]);
};
