import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'device',
  initialState: {
    mobile: false,
    tablet: false,
    desktop: false,
  },
  reducers: {
    SET_MOBILE: (device) => {
      device.mobile = true;
      device.tablet = false;
      device.desktop = false;
    },

    SET_TABLET: (device) => {
      device.mobile = false;
      device.tablet = true;
      device.desktop = false;
    },

    SET_DESKTOP: (device) => {
      device.mobile = false;
      device.tablet = false;
      device.desktop = true;
    },
  },
});

export const { SET_MOBILE, SET_TABLET, SET_DESKTOP } = slice.actions;
export default slice.reducer;
