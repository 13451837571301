import React from 'react';
import styled from 'styled-components';

const StyledH3 = styled.h3`
  margin-bottom: ${({ theme }) => theme.elements.general.h3.marginBottom};
  font-weight: ${({ theme }) => theme.elements.general.h3.fontWeight};
  font-family: ${({ theme }) => theme.elements.general.h3.fontFamily};
  line-height: ${({ theme }) => theme.elements.general.h3.lineHeight};
`;

const H3 = (props) => {
  return (
    <StyledH3 {...props} />
  );
};

export default H3;
