import React from 'react';
import ContactForm from '../components/contactForm';
import styled, { keyframes } from 'styled-components';
import HeroSection from '../components/common/heroSection';
import Container from '../components/common/container';
import VAlign from '../components/common/valign';
import { BsEnvelope } from 'react-icons/bs';
import { FiTwitter } from 'react-icons/fi';
import Small from '../components/common/small';

import labsterImg from '../images/roll/labster.png';
import codeableImg from '../images/roll/codeable.png';
import myplatesImg from '../images/roll/plates.png';
import powerspacesImg from '../images/roll/powerspaces.png';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.elements.contact.background};
  transition: ${({ theme }) => theme.general.transitions};
`;
const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;
const FormBoxWrapper = styled.div`
  & h1 {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  & label {
    font-family: 'Open Sans',sans-serif;
    display: inline-block;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.5;
    color: ${({ theme }) => theme.elements.contact.form.label.color};
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 4px;
  }
  & textarea {
    min-height: 170px;
  }
  & input,
  & textarea {
    background: transparent;
    padding: 12px;
    font-size: 14px;
    font-family: 'Open Sans',sans-serif;
    border: 1px solid transparent;
    border-bottom: 1px solid #eaeaea;
    border-radius: 0;
    transition: ${({ theme }) => theme.general.transitions};
  }
  & textarea {
    border: 1px solid #eaeaea;
  }
  & input:focus,
  & textarea:focus{
    border:1px solid #4154f1;
    box-shadow: inset 0 0 8px 0 rgba(65,84,241,0.02);
  }
  & button {
    min-height: 45px;
    cursor: pointer;
    border: 0;
    display: block;
    width: 100%;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #4154f1;
    box-shadow: ${({ theme }) => theme.elements.contact.form.button.boxShadow};
    text-decoration: none;
    letter-spacing: 1px;
    &:hover {
      background: #485092;
    }
  }
  & button:disabled {
    border: none;
    position: static;
    overflow: hidden;
    animation: ${placeHolderShimmer} 1s linear;
    animation-iteration-count: infinite;
    background-color: #fff;
    background-image: linear-gradient(to right,rgba(0,0,0,.08) 0,rgba(0,0,0,.15) 15%,rgba(0,0,0,.08) 40%);
    background-size: 1200px 100%;
    max-width: 30rem;
    height: 45px;
    font-size: 0;
    box-shadow: none;
    &:hover {
      background-color: #fff;
      background-image: linear-gradient(to right,rgba(0,0,0,.08) 0,rgba(0,0,0,.15) 15%,rgba(0,0,0,.08) 40%);
      background-size: 1200px 100%;
    }
    &:after {
      content: "Please complete all fields";
      color: #444;
      font-weight: bold;
      display: block;
      font-size: 12px;
    }
  }
  
`;
const FluidContainer = styled(Container)`
  width: 100%;
`;
const VerticalMiddle = styled(VAlign)`
  width: 100%;
`;
const Separator = styled.hr`
  margin: 30px 0;
  border: 2px solid #f3f4f6;
`;
const ContactBox = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme.elements.contact.form.background};
  color: ${({ theme }) => theme.elements.contact.form.color};
  box-shadow: ${({ theme }) => theme.elements.contact.boxShadow};
  padding: 40px;
  border-radius: 4px;
  & input,
  & textarea {
    color: ${({ theme }) => theme.elements.contact.form.color};
  }
`;
const InfoBox = styled.div`
  & p {
    font-size: 14px;
    font-family: 'Open Sans',sans-serif;
    transition: ${({ theme }) => theme.general.transitions};
  }
  & a > svg {
    font-size: 18px;
    margin-right: 10px;
    color: #4154f1;
    transition: ${({ theme }) => theme.general.transitions};
  }
  & a:hover > svg {
    fill: #485092;
    color: #485092;
    transition: ${({ theme }) => theme.general.transitions};
  }
  & h3 {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 30px;
  }
  & a {
    font-size: 14px;
    font-family: 'Open Sans',sans-serif;
    text-decoration: none;
    color: ${({ theme }) => theme.elements.contact.link.a.color};
    transition: ${({ theme }) => theme.elements.general.transitions};
  }
  & a:hover {
    color: ${({ theme }) => theme.elements.contact.linkActive.a.color};
  }
`;
const BookingArea = styled.div`
  padding: 40px;
  & > small {
    margin-top: 60px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #c52a2a;
  }
`;
const BookingActions = styled.ul`
  padding: 0;
  margin-top: 40px;
  & a {
    text-decoration: none;
  }
  & a h3 {
    color: ${({ theme }) => theme.elements.contact.link.h3.color};
    margin-top: 4px;
    margin-bottom: 14px;
  }
  & li {
    border-bottom: 1px solid #ccc;
    list-style-type: none;
    padding: 10px 16px 10px 0;
    position: relative;
    transition: ${({ theme }) => theme.general.transitions};
  }
  & li:after {
    content: '\u203A';
    display: block;
    font-size: 2rem;
    color: #ccc;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    transition: ${({ theme }) => theme.general.transitions};
  }
  & li:hover:after,
  & li:hover a h3{
    color: ${({ theme }) => theme.elements.contact.linkActive.h3.color};
  }
  & li:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.elements.contact.listItemActive.background};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;
  }
  & li > a > small {
    color: #6f859b;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0;
    text-transform: none;
  }
`;
const TrustLogos = styled.ul`
  padding: 0;
  display:flex;
  align-items:center;
  user-select: none;
  & li {
    width: 25%;
    list-style-type: none;
    display: inline-flex;
    box-sizing: border-box;
    align-items:center;
    user-select: none;
  }
  & li > img {
    max-width: 90%;
    margin: 0 5%;
    filter: ${({ theme }) => theme.elements.contact.img.filter};
    transition: ${({ theme }) => theme.general.transitions};
    opacity: .7;
    user-select: none;
  }
`;
const ContentArea = styled(HeroSection)`
  padding-top: 130px;
  padding-bottom: 100px;
  box-sizing: border-box;
`;
const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.elements.general.h1.color};
`;
const Paragraph = styled.p`
  color: ${({ theme }) => theme.elements.general.h1.color};
`;

const Contact = () => {
  return (
    <Wrapper>
      <ContentArea>
        <VerticalMiddle align="middle">
          <FluidContainer>
            <div className="row">
              <FormBoxWrapper className="col-lg-4 col-md-4 col-xs-12">
                <ContactBox>
                  <ContactForm />
                  <Separator />
                  <InfoBox>
                    <h3>Contact Information</h3>
                    <p><a href="mailto:contact@cristiansimion.com"><BsEnvelope />contact@cristiansimion.com</a></p>
                    <p><a href="https://twitter.com/messages/compose?recipient_id=408994278&text=Hello%20Cristian!%20I%27m%20messaging%20your%20from%20your%20portfolio." target="_blank" rel="noreferrer"><FiTwitter />@cristiansimion1</a></p>
                  </InfoBox>
                </ContactBox>
              </FormBoxWrapper>
              <VAlign className="col-lg-8 col-md-8 col-xs-12">
                <BookingArea>
                  <StyledH1>How can I help?</StyledH1>
                  <Paragraph>Please select a topic below related to Your inquiry. If you {'don\'t'} find what you need, fill out the contact form on the left.</Paragraph>
                  <BookingActions>
                    <li>
                      <a href="https://app.codeable.io/tasks/new?preferredContractor=63456" target="_blank" rel="noreferrer">
                        <h3>Book a consultation</h3>
                        <Small>Book a 1-hour consultation with me to discuss any architectural designs.</Small>
                      </a>
                    </li>
                    <li>
                      <a href="https://app.codeable.io/tasks/new?preferredContractor=63456" target="_blank" rel="noreferrer">
                        <h3>Start a project</h3>
                        <Small>Over-the-shoulder work? Create a new exciting application or website? {'Let\'s'} discuss!</Small>
                      </a>
                    </li>
                    <li>
                      <a href="https://liquidwordpress.com/" target="_blank" rel="noreferrer">
                        <h3>Launch a WordPress website instantly</h3>
                        <Small>Need to start a WordPress website and self-manage the content? Click here.</Small>
                      </a>
                    </li>
                  </BookingActions>
                  <Small>Trusted by</Small>
                  <TrustLogos>
                    <li><img draggable={false} src={labsterImg}/></li>
                    <li><img draggable={false} src={codeableImg} /></li>
                    <li><img draggable={false} src={myplatesImg} /></li>
                    <li><img draggable={false} src={powerspacesImg} /></li>
                  </TrustLogos>
                </BookingArea>
              </VAlign>
            </div>
          </FluidContainer>
        </VerticalMiddle>
      </ContentArea>
    </Wrapper>
  );
};

export default Contact;
