import React from 'react';
import styled from 'styled-components';

const Hero = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: ${({ valign }) =>
    (valign === 'bottom') ? 'flex-end'
      : (valign === 'top') ? 'flex-start'
        : (valign === 'middle') ? 'center' : valign};
  background: ${({ background }) => background || null};
  background-attachment: ${({ backgroundAttachment }) => backgroundAttachment || null};
  background-size: ${({ backgroundSize }) => backgroundSize || 'cover'};
  filter: ${({ filters }) => filters || null};
  transition: ${({ theme }) => theme.general.transitions};
`;

const HeroSection = (props) => {
  return (
    <Hero {...props}/>
  );
};

export default HeroSection;
