import React from 'react';
import PageHome from '../../pages/home';
import WorkSamples from '../../pages/workSamples';
import Contact from '../../pages/contact';
import TermsAndConditions from '../../pages/termsAndConditions';
import PrivacyPolicy from '../../pages/privacyPolicy';

export const home = {
  exact: true,
  path: '/',
  label: 'Home',
  content: <PageHome />,
};
export const workSamples = {
  exact: false,
  path: '/work/samples',
  label: 'Work Samples',
  content: <WorkSamples />,
};
export const privacyPolicy = {
  exact: false,
  path: '/privacy-policy',
  label: 'Privacy Policy',
  content: <PrivacyPolicy />,
};
export const termsAndConditions = {
  exact: false,
  path: '/terms',
  label: 'Terms and Conditions',
  content: <TermsAndConditions />,
};
export const contact = {
  exact: false,
  path: '/contact',
  label: 'Contact',
  content: <Contact />,
};
export const notFound = {
  exact: false,
  path: '/not-found',
  label: 'Not Found',
  content: <h3>Ooops! Sorry, page not found.</h3>,
};
