import React from 'react';
import H1 from '../components/common/headings/h1';
import styled, { createGlobalStyle } from 'styled-components';
import Align from '../components/common/alignText';
import Container from '../components/common/container';
import Small from '../components/common/small';
import { curvyBackground } from '../components/constants/images';

const LocalStyle = createGlobalStyle`
  header {
    background: #fff;
  }    
`;
const BackgroundSection = styled.div`
  background: url(${curvyBackground}) center center no-repeat;
  background-size: cover;
  padding-top:90px;
  display: flex;
  align-items: center;
  min-height: 30vh;
  padding-bottom: 120px;
  & > div {
    width: 100%;
  }
  & h1 {
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    letter-spacing: 2px;
    color: #121212;
  }
`;

const StyledContainer = styled(Container)`
  font-family: 'Open Sans', sans-serif;
  position: relative;
  background: #fff;
  padding: 60px;
  top: -120px;
  margin-bottom: -120px;
  & h3 {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 1.6rem;
    font-weight: 900;
  }
  & > div {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
  }
`;

const TermsAndConditions = () => {
  return (
    <div>
      <LocalStyle />
      <BackgroundSection>
        <Align direction="center">
          <H1>Freelance Terms and Conditions</H1>
          <h3>CRISTIAN GEORGE SIMION AND DONNA LYNN</h3>
        </Align>
      </BackgroundSection>
      <StyledContainer>
        <Small>Last edited: 21 April 2021</Small>
        <h3>PRICING and PAYMENT</h3>
        <div>Projects are billed hourly; specific fees will be discussed between myself and the client. Fees are non-refundable. Invoices must be paid before work shall be allowed to continue. Any variations in pricing and payment must be explicitly agreed upon between myself and the client.</div>

        <h3>ESTIMATES</h3>
        <div>
          <p>I make every effort to make estimates as accurate and complete as possible, however clients must be aware that actual working hours may vary. If, upon receipt of all project elements, I determine the scope of the project has been changed from the originally agreed-upon concept, the project may be re- estimated.</p>
          <p>Minor revisions are anticipated, but major design/content/functionality changes may go beyond the scope of the original estimate. The client’s approval will be obtained for any increases in fees or expenses that exceed the original estimate by 10%. Final fees and expenses will be shown when invoice is rendered.</p>
        </div>

        <h3>ORAL AUTHORIZATION</h3>
        <div>Invoices will include (and the client shall be obligated to pay) fees or expenses that were orally authorized.</div>

        <h3>REFUNDS</h3>
        <div>Client understands that all hours paid are for consultation ( hourly labor ) and that the hours worked or consulted are non-refundable. Refunds will be given on a case-by-case basis and refunds of unspent/unused working hours shall be returned to the client upon request approval.</div>

        <h3>COMPLETION/DELIVERY DATES</h3>
        <div>Any delay in the completion of a project due to external forces beyond my control (such as unusual transportation delays, unforeseen problems at a vendor site, computer/Internet related issues, holidays, important personal or family issues, etc.), or actions and negligence of the client (i.e., content delivery or approval delays), shall entitle me to extend the completion/delivery date, upon notifying the client, by the time equivalent of such a delay.</div>

        <h3>CANCELLATION FEES</h3>
        <div>In the event of cancellation of a project, the ownership of all copyrights on unpaid work shall be retained by me. Any usage by the client of any of the project elements may result in appropriate legal action. Upon cancellation the client will be invoiced for any work already completed.</div>

        <h3>COPYRIGHT RESPONSIBILITY</h3>
        <div>It is the sole responsibility of the client to ensure that any intellectual property (artwork, code, etc.) that they provide is owned by them in accordance with copyright laws. I cannot be held responsible for any copyright violations or resulting fees due to assets provided by the client. Likewise, I am responsible for any intellectual property I supply.</div>

        <h3>CREATIVE/INTELLECTUAL PROPERTY</h3>
        <div>All code parts produced during the work relationship shall be owned by myself and may be reused in future projects, although the actual implementation (website, web app, etc.) of that code is solely the client’s, and I shall not reproduce that same final implementation elsewhere.</div>

        <h3>LIMITATION OF LIABILITY</h3>
        <div>I cannot be held liable for any consequential or special damages, such as profit losses.</div>

        <h3>PROMOTIONAL USE</h3>
        <div>I may reproduce any design, artwork, or layout in promotional materials such as brochures, mailers, and websites, unless otherwise requested by the client. I may use the completed project and any preliminary designs for the purpose of my online portfolio, design competitions, future publications on design, educational purposes, and the marketing of myself, unless otherwise requested by the client.</div>

        <h3>LEGAL FEES</h3>
        <div>Client shall bear all costs, expenses, and reasonable attorney’s fees in any action brought to recover payment under this contract or in which I may become a party by reason of this contract.</div>
      </StyledContainer>
    </div>
  );
};

export default TermsAndConditions;
