import React from 'react';
import Joi from 'joi-browser';
import Form from '../components/common/forms/form';
import env from 'react-dotenv';
import styled from 'styled-components';
import Reaptcha from 'reaptcha';

const Notice = styled.div`
  margin-top: 10px;
  display: ${({ isSuccess }) => isSuccess === null ? 'none' : 'block'};
  background: ${({ isSuccess }) => isSuccess !== true ? '#f39c9c' : '#a1e1af'};
  border: 1px solid ${({ isSuccess }) => isSuccess !== true ? '#ce7777' : '#97d4a4'};
  color: #222;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  text-align: center;
`;

const Loading = styled.div`
  margin-top: 10px;
  display: ${({ isLoading }) => isLoading ? 'block' : 'none'};
  color: #222;
  background: #fff7d2;
  border: 1px solid #ffdc85;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  text-align: center;
`;

class ContactForm extends Form {

  state = {
    data: {
      name: '',
      email: '',
      message: '',
      _csrf: '',
    },
    isLoading: false,
    errors: {},
    sent: {
      success: null,
      message: [],
    },
  }

  schema = {
    name: Joi.string().required().label('Name field'),
    email: Joi.string().email().required().label('Email field'),
    message: Joi.string().required().label('Message field'),
    _csrf: Joi.string().required('Security field'),
  }

  constructor (props) {
    super(props);
    this.captcha = null;
  }

  doSubmit = () => {
    this.setState({
      ...this.state,
      isLoading: true,
      sent: {
        success: null,
        message: [],
      },
    });
    this.captcha.execute();
  }

  onCaptchaValidate = async verified => {
    if (verified) {
      const response = await fetch(env.ENDPOINT_EMAIL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.data),
      });

      const sent = await response.json();
      this.setState({
        ...this.state,
        sent,
      });
    } else {
      this.setState({
        ...this.state,
        sent: {
          success: false,
          message: [
            'Captcha error',
            'Please verify you\'re a human',
          ],
        },
      });
    }

    this.setState({
      ...this.state,
      isLoading: false,
    });
    this.captcha.reset();
  }

  async componentDidMount () {
    try {
      const response = await fetch(env.ENDPOINT_CSRF);
      const _csrf = await response.json();

      this.setState({
        data: {
          ...this.state.data,
          _csrf: _csrf.token,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)) {
      if (this.captcha.state.ready !== 'undefined' && this.captcha.state.ready) {
        this.captcha.reset();
      }
      this.setState({
        ...this.state,
        sent: {
          success: null,
          message: [],
        },
      });
    }
  }

  render () {
    const { errors, sent: apiResponse } = this.state;

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'text', 'Name', 'Type your name...')}
          {this.renderInput('email', 'email', 'Email', 'Type your email...')}
          {this.renderTextArea('message', 'Message', 'Start typing here...')}
          {this.renderInput('_csrf', 'hidden')}
          {this.renderButton('SEND MESSAGE')}
          <Reaptcha
            ref={e => (this.captcha = e)}
            size="invisible"
            // sitekey="6LeLs8QaAAAAANLwl_PyqLjESkK6a30Y33IZO4nF"
            sitekey="6Lcbx8QaAAAAAGN3xRFcirPoiyWPI1cvHHCWbtzx"
            onVerify={this.onCaptchaValidate}
          />
        </form>
        <Loading isLoading={this.state.isLoading}>
          Loading. Please wait
        </Loading>
        <Notice
          isSuccess={apiResponse.success && !Object.keys(errors).length}
        >
          {apiResponse.message.length && React.createElement('div', null, React.createElement('div', {}, [
            apiResponse.message.map((line, index) => (
              <div key={index} style={{ fontWeight: (!index) ? 'bold' : 'normal' }}>{line}</div>
            )),
          ]))}
        </Notice>
      </React.Fragment>
    );
  }
}

export default ContactForm;
